.max-w-1k {
    /* max-width: 1360px !important; */
}

.wrapper.table-lg{
    overflow-x: auto;
    width: 100%;
}

#legal-entity-table {
    width: 6459px;
}

#legal-entity-table .table-row {
    display: table-row;
    border-color: #000 !important;
}

#legal-entity-table .table-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    overflow-wrap: anywhere;
    margin: auto;
    padding-left: 6px;
    padding-right: 6px;
}

#legal-entity-table tr th{
   padding: 0.75rem;
   background-color: #212529 !important;
   color: white;
   font-weight: normal !important;
}

#legal-entity-table tr td{
    padding: 0.75rem;
}

#pageContent{
    min-height: calc(100vh - 170px);
}

span.failed {
    color: red;
}

span.success, span.pass {
    color: green;
}

.table-row.table-body {
    font-size: 16px;
    /* height: 98px; */
    border-radius: 10px !important;
    margin-top: 14px !important;
}

.table-row.table-header {
    font-size: 18px;
    font-weight: 700;    
    /* height: 58px; */
    
}

.col-num-23{
    min-width: 333px;
}

.col-num-1 {
    max-width: 70px;
}

.col-num-2 {
    max-width: 155px;
}